@font-face {
    font-family: 'main_font';
    src: url(../../fonts/Montserrat-Regular.ttf);
}

.home_page{
    background-color: #F6FCFE;
    padding: 2em 3em;
    overflow-x: hidden;
    width: 100%;
}

.ourCode_txt{
    width: 40%;
}

.ourCode_txt h4{
    font-size: 50px;
    font-family: 'main_font';
    font-weight: bold;
    margin: 0;
    padding: 8px;
    color: #000;
}

.ourCode_txt p{
    font-size: 20px;
    font-family: 'main_font';
    color: #000;
    line-height: 33px;
    font-weight: 500;
}

.our_code_img{
    width: 50%;
    padding: 0em 2em;
}

.our_code_img img{
    width: 100%;
}

.our_code{
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding: 3em 0em;
}

.our_code::after{
    content: '';
    width: 150px;
    position: absolute;
    top: 0;
    right: -80px;
    height: 150px;
    background-color: #AFDCC9;
    border-radius: 15px 100px 15px 15px;
}

@media (max-width:1024px) {

    .ourCode_txt{
        width: 50%;
    }

    .ourCode_txt h4{
        font-size: 35px;
    }
    .home_page{
        padding: 2em 5em;
    }

    .ourCode_txt p{
        font-size: 18px;
    }
}
@media (max-width:700px) {
    .our_code{
        flex-direction: column;
    }

    .our_code_img{
        width: 100%;
    }

    .ourCode_txt{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .ourCode_txt p,.ourCode_txt h4{
        text-align: center;
    }
}

@media (max-width:550px) {
    .home_page{
        padding: 2em 1.5em;
    }
}

@media (max-width:1300px) {
    .our_code::after{
        display: none;
    }
}