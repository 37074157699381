
@font-face {
    font-family: 'main_font';
    src: url(../../fonts/Montserrat-Regular.ttf);
}

.etlas_features{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    grid-template-columns: min-content;
}


.features_section{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    padding: 0em 0;
    align-items: center;
}

.features_section h4{
    font-size: 50px;
    font-family: 'main_font';
    color: #000;
    text-align: center;
}

.features_box{
    width: 38%;
    height: 80%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;

}

.features_box_row_2{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

}

.feature_img{
    width: 62%;
    height: 80%;
    
}

.feature_img img{
    width: 100%;
    
}


.feature h5{
    font-size: 24px;
    color: #000;
    font-family: 'main_font';
    font-weight: bold;
    margin: 0;
}
.feature_row_2 h5{
    font-size: 24px;
    color: #000;
    font-family: 'main_font';
    font-weight: bold;
    margin: 0;
}

.feature img{
    width: 140px;
    height: 140px;

}

.feature_row_2 img{
    width: 140px;
    height: 140px;

}

 .feature:nth-child(1) {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    text-align: right;
    margin-left: 3em;
    
}
 .feature:nth-child(2) {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    text-align: right;
    margin-left: 3em;
    
}
 .feature:nth-child(3){
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    text-align: center;
}

.feature_row_2:nth-child(1) {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    text-align: center;
    width: 33%;
    padding: 0em 3em;
}
 .feature_row_2:nth-child(2) {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    text-align: center;
    width: 33%;
    padding: 0em 3em;
}
 .feature_row_2:nth-child(3){
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    text-align: center;
    width: 33%;
    padding: 0em 3em;
}

.feature p{
    font-size: 20px;
    font-family: 'main_font';
    color: #000;
    font-weight: 500;
}

.feature_row_2 p{
    font-size: 20px;
    font-family: 'main_font';
    color: #000;
    font-weight: 500;
}

/*.features_box:nth-child(1) .feature h5 ,.features_box:nth-child(1) .feature p{
    text-align: right;
}
.features_box:nth-child(2) .feature h5 ,.features_box:nth-child(2) .feature p{
    text-align: right;
}
.features_box:nth-child(4) .feature h5 ,.features_box:nth-child(4) .feature p{
    text-align: center;
}*/

@media (max-width:1024px) {
    .features_box{
        width: 30%;
    }
    .feature_img{
        display: flex;
        flex-wrap: wrap;
        width: 70%;
        height: 100%;
        align-items: center;
    }
    
    .feature p{
        font-size: 16px;
        font-family: 'main_font';
        color: #000;
        font-weight: 500;
    }

    .feature h5{
        font-size: 20px;
        color: #000;
        font-family: 'main_font';
        font-weight: bold;
        margin: 0;
    }

    .feature_row_2 p{
        font-size: 16px;
        font-family: 'main_font';
        color: #000;
        font-weight: 500;
    }

    .feature_row_2 h5{
        font-size: 20px;
        color: #000;
        font-family: 'main_font';
        font-weight: bold;
        margin: 0;
    }
}

@media (max-width:900px) {

    .features_box{
        width: 100%;
    }

    .features_box_row_2{
        width: 100%;
        display:flex;
        flex-direction: wrap;
        flex-direction: column;
    }  

    .feature:nth-child(1){
        align-items: center !important;
        margin-left: 0em;
    }
    .feature:nth-child(2){
        align-items: center !important;
        margin-left: 0em;
    }

    .feature_row_2:nth-child(1){
        align-items: center !important;
        width: 100%;
        padding: 0em 0em;
    }
    .feature_row_2:nth-child(2){
        align-items: center !important;
        width: 100%;
        padding: 0em 0em;
    }
    .feature_row_2:nth-child(3){
        align-items: center !important;
        width: 100%;
        padding: 0em 0em;
    }

    .feature h5 ,.feature p{
        text-align: center !important;
    }

    .feature_img{
        width: 100%;
    }
    .features_section h4{
        font-size: 35px;
        margin: 0;
        padding: .4em 0;
    }

}