@font-face {
    font-family: 'main_font';
    src: url(../../fonts/Montserrat-Regular.ttf);
}

body{overflow-x: hidden;}


.banner_section{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.banner_section::before{
    content: '';
    width: 150px;
    height: 150px;
    background-color: #63C6F1;
    border-radius: 15px 100px 15px 15px ;
    position: absolute;
    bottom: 0;
    left: -70px;
}

.banner_txt{
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.banner_txt h3{
    font-size: 80px;
    font-family: 'main_font';
    color: #000;
    text-align: center;
    margin: 0;
    padding: 6px 0;
    font-weight: 700;
}

.banner_txt p{
    font-size: 24px;
    font-family: 'main_font';
    color: #000;
    text-align: center;
    margin: 0;
    padding: 1.3em 0;
    font-weight: 500;
}

.banner_txt div{
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.banner_txt div input{
    border: none;
    outline: none;
    box-shadow: -3px 5.2px 20px rgba(0, 0, 0, .15);
    width: 100%;
    font-size: 24px;
     font-family: 'main_font';
     padding: 0 0 0 1.4em;
    height: 80px;
    border-radius: 10px;
}

.banner_txt div button{
    height: 60px;
    background-color: #1E3A39;
    padding: 0 1.5em;
    position: relative;
    border-radius: 10px;
    
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-family: 'main_font';
    font-weight: 600;
    font-size: 26px;
    transition: .3s;
}

.banner_txt div a{

    font-size: 20px;
    font-family: 'main_font';

    font-weight: 600;
    color: #fff;
    text-decoration: none;
}

.banner_txt div button:hover{
    background-color: rgba(30, 58, 57 ,.9);

}

.banner_img{
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.banner_main_img{
    background: url(../../asset/bannerBG.png);
    background-repeat: no-repeat;
    background-size: contain;
    padding: 4em 0;
    width: 65%;
    background-position: center;
}

.banner_icons{
    position: absolute;
    width: 100%;
}

.banner_mob_icons{
    display: none;
}

@media (max-width:1024px) {
    .banner_txt{
        width: 100%;
    }

    .banner_txt div{
        width: 100%;
    }
}

@media (max-width:700px) {

   
    .banner_icons{
        display: none;
    }
    .banner_txt h3{
        font-size: 40px;
    }

    .banner_txt p{
        font-size: 18px;
    }

    .banner_txt div button{
        position: static;
        margin: 0 0 0 0;
        height: 60px;
        width: 200px;
        padding: 0;
    }

    .banner_main_img{
        width: 100%;
    }

    .banner_txt div{
        flex-direction: column;
    }
    .banner_txt div input{
        font-size: 18px;
    }

    .banner_main_img{
        background-size: 85%;
    }

    .banner_section::before{
        display: none;
    }
}